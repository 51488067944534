$(document).ready(function() {
  // Cuando el viewport es menos ancho de 767px espera a que se vea la foto del header
  // y hace scroll hasta dejar el buscdor arriba para evitar que los resultados se oculten
  // detras del teclado del mobil
  if (window.matchMedia("(max-width: 767px)").matches) {
    const input_buscador = document.getElementById('tenemos-la-solucion');

    $('#buscador').on('keyup', function() {
      setTimeout(function () { input_buscador.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }); }, 30);
    });
  }
  
  // Trae los datos de la viasta buscador y los convierte a array para el autocomplete de jquery ui
  $.get("/buscador-data", {
    }, function (data) {
      // LLama a la funcion que inicializa el input autocomplete
      buscador(
        $.map(data.data, function (value, key) {
          return {
              label: value.nombre,
              tipo: value.tipo,
              id: value.id
          };
        })
      );
  });

  

  /**
   * Inicializa el buscador y corta el resultado a mostrar en 6 filas
   * @param  array data datos donde buscar
   */
  function buscador(data) {

    $("#buscador").autocomplete({
      source: function(request, response) {
        var results = $.ui.autocomplete.filter(data, request.term);

        response(results.slice(0, 6));
      },
      select: function( event, ui ) {
          $('#Btn-Buscar-Solucion').attr("data-tipo", ui.item.tipo);
          $('#Btn-Buscar-Solucion').attr("data-id", ui.item.id);
      },
      focus: function(event, ui) {
        $(".lista-buscador li").removeClass('buscador-item-hover');
        $(".lista-buscador li:contains("+ui.item.label+")").addClass('buscador-item-hover');
      },
      position: { my : "left top", at: "left bottom", of: "#wrap-buscador" }
    }).autocomplete("widget").addClass( "lista-buscador" );
  }

  $('#Btn-Buscar-Solucion').on('click', function(e) {
      var url = this.href + '/' + $(this).data('tipo') + '/' + $(this).data('id');
      e.preventDefault();

      window.location.href = url;
  });

});
